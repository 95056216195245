console.log("Andrew Larner — Graphic & Web Design")
const html = document.documentElement

const checkJS = () => {
  html.classList.remove('no-js')
  html.classList.add('has-js')
}
checkJS()

/* --------------------------------------
------- Loading fadein transition -------
-------------------------------------- */
const loadingTag = document.querySelector('span.loading-fade')
let transitionEnded = false

if (loadingTag) {
  loadingTag.addEventListener('transitionend', () => {
    transitionEnded = true
    // if transition has finished add a class
    if (transitionEnded) {
      html.classList.add('loading-complete')
    }
  })
}

/* --------------------------------------
------- Generate a random number --------
-------------------------------------- */
const numberTag = document.querySelector('span.js-text.number')
const timespanTag = document.querySelector('span.js-text.timespan')
const messageTag = document.querySelector('span.js-text.message')
// use the number-to-words library to convert a number to words
const converter = require('number-to-words')

// create the random number
const outputRandomNumber = (min, max) => {
  // calculate a random number between the two min and max points
  const number = Math.floor(Math.random() * (max - min + 1)) + min
  numberTag.textContent = converter.toWords(number)
}

outputRandomNumber(1,10)


/* --------------------------------------
----- Generate the timespan period ------
-------------------------------------- */
// create the timespan array
const outputRandomTimespan = () => {
  // find the created random number
  const number = numberTag.textContent
  // set up the timespan array
  const timespan = ['minute', 'hour', 'day']
  // get a random string from the array
  const randomTimespan = timespan[Math.floor(Math.random() * timespan.length)]
  // output the timespan, if number is not 'one' output the timespan with an 's', else output non-plural version
  timespanTag.textContent = (number != 'one' ? `${randomTimespan}s` : randomTimespan)

}

outputRandomTimespan()


/* --------------------------------------
----- Generate the timespan message -----
-------------------------------------- */
// output a random message from the array
const outputRandomMessage = () => {
  const commaTag = document.querySelector('span.kern')
  // set the message array
  const message = ['any time now', 'some time soon', 'give it a week', 'almost there']
  // get a random message with Math
  const randomMessage = message[Math.floor(Math.random() * message.length)]
  // find the last character of the message
  const checkCharacter = randomMessage.slice(-1)
  // output the message
  messageTag.textContent = randomMessage
  // if the last character needs to be kerned
  if (checkCharacter === 'w' ) {
    commaTag.classList.add('large')
  }
}

outputRandomMessage()


/* --------------------------------------
------ Typed text / First message -------
-------------------------------------- */
// click prompt type out
let count = 0
let countDelete = 0
let countAnswer = 0
// get the message elements
const typedTag = document.querySelector('.prompt')
const typedTagText = document.querySelector('span.prompt-text')
const typedMessage = typedTag.getAttribute('data-message')
const typedAnswer = typedTag.getAttribute('data-answer')
// set the speed for the timeout
const speed = 80

// type out the first message
const typeoutText = () => {

  if (count < typedMessage.length) {
    // output each character one by one with charAt
    typedTagText.textContent += typedMessage.charAt(count)
    // update the count - increment by 1
    count++
    // timing function
    setTimeout(typeoutText, speed)

    typedTag.classList.add('pause-animation')
  } else if (count >= typedMessage.length) {
    typedTag.classList.remove('pause-animation')
  }

}

/* --------------------------------------
------ Typed text / Second message ------
-------------------------------------- */
// type out the second message
const typeoutFinalText = () => {
  if (countAnswer < typedAnswer.length) {
    typedTagText.textContent += typedAnswer.charAt(countAnswer)
    // update the count
    countAnswer++
    // timing function
    setTimeout(typeoutFinalText, speed)
    // add the pause class
    typedTag.classList.add('pause-animation')
  } else if (countAnswer >= typedAnswer.length) {
    typedTag.classList.remove('pause-animation')
  }
}

/* --------------------------------------
--- Typed text / Delete first message ---
-------------------------------------- */
// delete the first message
const typebackText = () => {
  // get the message
  const messageText = typedTagText.textContent
  
  if (countDelete < typedMessage.length) {
    // update the count increment by 1
    countDelete++
    // use slice to remove the last letter each time
    typedTagText.textContent = messageText.slice(0,-1)
    // run the timeout
    setTimeout(typebackText, speed)
    // add the class to the text line
    typedTag.classList.add('pause-animation')
  } else if (countDelete >= typedMessage.length) {
    typedTag.classList.remove('pause-animation')
    typedTag.classList.add('message-stage-two')

    setTimeout(() => {
      typeoutFinalText()
    },500)

  }

}

/* --------------------------------------
-------- Set up the images array --------
-------------------------------------- */
// click show images
const images = [

  { 
    name: 'ae-index.acb59982.jpg',
    title: 'Argent Energy',
    description: "Website Design + Development",
    class: "wide",
  },
  
  { 
    name: 'bd-index.41a22583.jpg',
    title: 'BioDie2020',
    description: "Identity, Website Design + Development",
    class: "tall",
  },

  { 
    name: 'bt-index.bf6c7774.jpg',
    title: 'Bromsgrove Urban & Rural Transport',
    description: "Illustration, Print",
    class: "tall",
  },

  { 
    name: 'fbm-index.8400caad.jpg',
    title: 'French Business Management',
    description: "Identity, Website Design + Development",
    class: "wide",
  },
  
  { 
    name: 'mh-index.2897fc9b.jpg',
    title: "Mad Hatter's Children's Charity",
    description: "Identity, Print, Website Design + Development",
    class: "tall",
  },
  
  { 
    name: 'sfg-index.17a5cdc2.jpg',
    title: "St Francis Group",
    description: "Website Design + Development",
    class: "wide",
  },

  { 
    name: 'tm-index.97f2949e.jpg',
    title: "Tuition Matters",
    description: "Identity, Website Design + Development",
    class: "tall",
  }

]

// cache images? – https://stackoverflow.com/questions/10240110/how-do-you-cache-an-image-in-javascript
const precacheImages = () => {
  
  images.forEach(image => {
    cacheImage = document.createElement('img')
    cacheImage.setAttribute('src', `/${image.name}`)
  })

}

/* --------------------------------------
------ Click and place the images -------
-------------------------------------- */
let imageCount = 0
const pageTag = document.querySelector('main.landing')
const imageTitleTag = document.querySelector('.index-title')
const imageDescTag = document.querySelector('.index-description')

const placeImage = (x, y) => {
  // image options
  const nextImage = images[imageCount]
  const imageName = nextImage.name
  const imageClass = nextImage.class
  const imageTitle = nextImage.title
  const imageDesc = nextImage.description

  // create a new div
  const imageContainTag = document.createElement('div')
  imageContainTag.classList.add('landing-image')

  // add the image class to the container - control the width with css
  if (imageClass == 'wide') {
    imageContainTag.classList.add('wide')
  } else if (imageClass == 'tall') {
    imageContainTag.classList.add('tall')
  }

  // create the image
  const imageTag = document.createElement('img')
  // set the image attributes
  imageTag.setAttribute("src", `/${imageName}`)
  imageTag.setAttribute("width", "100%")
  imageTag.setAttribute("height", "100%")
  imageTag.setAttribute("draggable", "false")
  imageTag.setAttribute("alt", `${imageTitle} — ${imageDesc}`)

  // set the image position
  imageContainTag.style.left = `${x}px`
  imageContainTag.style.top = `${y}px`
  // rotate the image
  // random number between -10 & 10
  const randomRotate = (Math.random() * (10 - -10 + 1) + -10).toFixed(2)
  imageContainTag.style.transform = `translate3d(-50%, -50%, 0) rotate(${randomRotate}deg)`

  // update the index title
  imageTitleTag.textContent = `${imageTitle} —`
  imageDescTag.textContent = imageDesc

  // add the image to div and then add it to the page
  imageContainTag.appendChild(imageTag)
  pageTag.appendChild(imageContainTag)

  // update the count
  imageCount = imageCount + 1
  if (imageCount >= images.length) {
    // if the count is greater than or equal to the amount of images
    imageCount = 0
  }

  // add the clear button class
  if (imageCount === 1) {
    clearButton.classList.add('active')
  }

}

/* --------------------------------------
----- remove the images from DOM  -------
-------------------------------------- */
// check if the fade animation has ended
let imagesFaded = false
// set the button variable
const clearButton = document.querySelector('button.clear-images')
// find all of the images and remove them
const clearAllImages = () => {

  clearButton.addEventListener('click', (e) => {
    // find all of the images in the DOM
    const allImages = document.querySelectorAll('.landing-image')
    let countAllImages = allImages.length
    // log the current click
    const currentClick = e.target
    // remove the clear button class
    clearButton.classList.remove('active')
    // remove the text from title/desc
    imageTitleTag.textContent = ''
    imageDescTag.textContent = ''

    imageCount = 0
    // loop through the images
    allImages.forEach(image => {
      // create a random transition period between 0.2 & 2
      const number = (Math.random() * (1 - .2 + 1) + .2).toFixed(2)
      // add the fade out class
      image.classList.add('fade-out')
      image.style.animationDelay = `${number}s`
      // now lets remove the images from the HTML
      image.addEventListener('animationend', () => {
        imagesFaded = true
        if (imagesFaded === true) {
          image.remove()
        }

      })
    })

  })

}

/* --------------------------------------
----- cache the images in browser  ------
-------------------------------------- */
precacheImages()


let countClick = 0
/* --------------------------------------
------- Check for the page load ---------
-------------------------------------- */
document.addEventListener('readystatechange', (e) => {
  if (e.target.readyState === 'complete') {
    html.classList.add('page-loaded')

    setTimeout(() => {
      typeoutText()
    }, 2500)

    // delay the first click
    setTimeout(() => {

      document.addEventListener("click", (e) => {
        // e.preventDefault()
        // get the clicked element
        const currentClick = e.target
        const buttonTag = currentClick.classList.contains('button')

        if (!buttonTag) {
          // if the click isn't on the button tag
          placeImage(e.pageX, e.pageY)
          // log the amount of clicks to typing function
          countClick += 1
          if (countClick === 1) {
            typebackText()
            clearAllImages()
          }
        }

      })

      // for touch
      document.addEventListener('touchend', (e) => {
        // e.preventDefault()
        // get the clicked element
        const currentClick = e.target
        const buttonTag = currentClick.classList.contains('button')

        if (!buttonTag) {
          // if the click isn't on the button tag
          placeImage(e.pageX, e.pageY)
          // log the amount of clicks to typing function
          countClick += 1
          if (countClick === 1) {
            typebackText()
            clearAllImages()
          }
        }
      })

    }, 3000)
  }
})


